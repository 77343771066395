/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

// AUTOGENERATED BY glean_parser v14.3.0. DO NOT EDIT. DO NOT COMMIT.

import EventMetricType from '@mozilla/glean/private/metrics/event';

/**
 * User clicks "Yes, approve device" on "Connect another device page"
 *
 * Generated from `cad_approve_device.submit`.
 */
export const submit = new EventMetricType(
  {
    category: 'cad_approve_device',
    name: 'submit',
    sendInPings: ['events'],
    lifetime: 'ping',
    disabled: false,
  },
  []
);

/**
 * User viewed the "Approve Device" page after scanning QR code
 *
 * Generated from `cad_approve_device.view`.
 */
export const view = new EventMetricType(
  {
    category: 'cad_approve_device',
    name: 'view',
    sendInPings: ['events'],
    lifetime: 'ping',
    disabled: false,
  },
  []
);
